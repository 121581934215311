import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Home, Puzzles } from "./Home/Home";
import Puzzle from "./Puzzle/Puzzle";
import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <Pages />
    </BrowserRouter>
  );
}

function Pages() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/puzzles" element={<Puzzles />} />
      <Route path="/puzzles/:name" element={<Puzzle />} />
    </Routes>
  );
}

export default App;
