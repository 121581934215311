import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import Page from "components/Page";

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 300px;
`;

const ButtonStyle = css`
  align-items: center;
  background: #019ade;
  border-radius: 24px;
  border: 2px solid white;
  box-shadow: 2px 4px 4px #00000033;
  color: white;
  display: flex;
  font-size: 2rem;
  font-weight: bold;
  height: 90px;
  justify-content: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 300px;
`;

const Button = styled.a`
  ${ButtonStyle}
`;

const ButtonLink = styled(Link)`
  ${ButtonStyle}
`;

const Title = styled.h1`
  color: white;
  font-size: 3rem;
  text-transform: uppercase;
  text-shadow: 2px 4px 4px #00000033;
`;

export function Home() {
  return (
    <Page>
      <Title>@T4Tabletop</Title>
      <Buttons>
        <Button href="https://twitch.tv/T4Tabletop">Twitch</Button>
        <Button href="https://youtube.com/@T4Tabletop">Youtube</Button>
        <Button href="https://instagram.com/T4Tabletop">Instagram</Button>
        <Button href="https://tiktok.com/@T4Tabletop">Tiktok</Button>
        <ButtonLink to="/puzzles">Puzzles</ButtonLink>
      </Buttons>
    </Page>
  );
}

export function Puzzles() {
  return (
    <Page>
      <Title>@T4Tabletop</Title>
      <Buttons>
        <ButtonLink to="/puzzles/altar-of-terrus">Episode 01</ButtonLink>
      </Buttons>
    </Page>
  );
}
