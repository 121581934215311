import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import Page from "components/Page";

const puzzles = {
  "altar-of-terrus":
    "https://puzzel.org/en/cryptogram/embed?p=-NljUuEpXdYVQEZGVfdq",
  "book-of-terrus":
    "https://puzzel.org/en/slidingpuzzle/embed?p=-NmKJXkM61YVpX694oxB",
};

const Wrapper = styled.div`
  max-width: 800px;
  width: 100%;
  height: calc(100vh - 64px);

  iframe {
    height: 100%;
  }
`;

function Puzzle() {
  const { name } = useParams();

  return (
    <Page>
      <Wrapper>
        <iframe
          title="puzzle"
          src={puzzles[name]}
          width="100%"
          frameborder="0"
        ></iframe>
      </Wrapper>
    </Page>
  );
}

export default Puzzle;
