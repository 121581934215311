import styled from "styled-components";

export default styled.div`
  align-items: center;
  background: linear-gradient(0deg, #019ade, #f2bfe0);
  display: flex;
  justify-content: start;
  flex-direction: column;
  color: white;
  padding: 2rem 1rem;
  height: 100%;
`;
